<template>
  <div class="d-flex align-center">
    <div
      v-for="device in devices"
      :key="device.id"
      class="d-flex align-center"
    >
      <v-menu
        offset-x
        right
        open-on-hover
        nudge-right="4px"
      >
        <template #activator="{ on, attrs }">
          <v-badge
            color="error"
            :value="vmWarning(device.virtual_machine_score)"
            overlap
            dot
          >
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :x-small="xSmall"
              v-html="icon(device)"
              :color="color"
            />
          </v-badge>

          <evalmee-chip
            v-show="showInlineVmScore"
            v-bind="attrs"
            v-on="on"
            small
            label
            class="ml-1"
            icon="mdi-incognito"
            :color="vmWarning(device.virtual_machine_score) ? 'red' : 'grey'"
          >
            {{ device.virtual_machine_score }}
          </evalmee-chip>
        </template>
        <user-device-card :device="device" />
      </v-menu>
    </div>


    <evalmee-chip
      v-show="multiMonitor.visible"
      small
      label
      :color="multiMonitor.color"
      class="ml-1"
      icon="mdi-monitor-multiple"
      icon-only
      v-tooltip.right="multiMonitorTooltip"
    />
  </div>
</template>

<script>

import UserDeviceCard from "./user_device_card.vue"
import userDeviceMixin from "./user_device_mixin"
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"
import stringHelper from "@/helpers/string_helpers"

export default {
  name: "UserDevices",
  components: { EvalmeeChip, UserDeviceCard },
  mixins: [userDeviceMixin],
  props: {
    // Array of Quizzes::StudentEvents::Device serialized from backend
    devices : {
      type: Array,
      default: () => [],
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    showInlineVmScore: {
      type: Boolean,
      default: false,
    },
    multipleMonitorConfidence: {
      type: [String, undefined],
      default: null,
    },
  },
  computed: {
    color() {
      return this.devices.length > 1 ? "red" : null
    },
    vmWarning() {
      return score => score > 5
    },
    multiMonitor() {
      return [
        { value: "low",       visible: false, color: "green" },
        { value: "medium",    visible: true,  color: "yellow" },
        { value: "high",      visible: true,  color: "orange" },
        { value: "very_high", visible: true,  color: "red" },
      ].find(c => c.value === this.multipleMonitorConfidence)
    },
    multiMonitorTooltip() {
      const level = this.$t(`evalmee.home.devices.multipleScreenConfidence.level.${this.toCamelCase(this.multipleMonitorConfidence)}`)
      return this.$t("evalmee.home.devices.multipleScreenConfidence.label", { level })
    },
  },
  methods: {
    toCamelCase(str) {
      return stringHelper.toCamelCase(str)
    },
  },
}
</script>
