<template>
  <v-card max-width="275">
    <v-card-title class="mx-n1">
      <v-icon
        v-html="icon(device)"
        class="mr-3"
      />
      {{ $t(`evalmee.home.devices.types.${device.type}`) }}
    </v-card-title>

    <v-list
      dense
      class="very-dense grey lighten-3"
    >
      <v-list-item
        v-for="(detail, index) in details"
        :key="`detail-${index}`"
        v-tooltip="detail.label"
      >
        <v-list-item-icon>
          <v-icon
            small
            v-html="detail.icon"
          />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-regular grey--text text--darken-2">
            {{ detail.value }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-light grey--text text--darken-1">
            {{ detail.subtitle }}
            <evalmee-chip
              v-if="detail.beta"
              label
              x-small
              color="red"
              v-html="'Beta'"
              class="ml-2"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>

import userDeviceMixin from "./user_device_mixin"
import EvalmeeChip from "../../../components/shared/evalmee_chip.vue"

export default {
  name: "UserDeviceCard",
  components: { EvalmeeChip },
  mixins: [userDeviceMixin],
  props: {
    device : {
      type: Object,
      required: true,
    },
  },

  computed: {
    details() {
      return [
        {
          label: this.$t("evalmee.home.devices.os"),
          icon: this.osIcon(this.device),
          value: this.device.os,
          subtitle: this.device.os_version,
        },
        {
          label: this.$t("evalmee.home.devices.browser"),
          icon: this.browserIcon(this.device),
          value: this.device.browser,
          subtitle: this.device.browser_version,
        },
        {
          label: this.$t("evalmee.home.devices.screenDefinition.label"),
          icon: "mdi-overscan",
          value: this.deviceScreenDefinitionText,
          // Disable this subtitle for now, as it is not very useful or understandable for end users
          // subtitle: `density: ${this.device.screen_pixel_density || "?"}, color: ${this.device.screen_color_depth || "?"} bits`,
        },
        {
          label: this.$t("evalmee.home.devices.vmScore"),
          icon: "mdi-incognito",
          value: this.vmScoreDetailsText,
          subtitle: `${this.device.virtual_machine_score}/10`,
          beta: true,
        },
      ]
    },

    deviceScreenDefinitionText() {
      if(this.device.screen_width && this.device.screen_height) {
        return `${this.device.screen_width}x${this.device.screen_height}px`
      } else {
        return this.$t("evalmee.home.devices.screenDefinition.unknownLabel")
      }
    },

    vmScoreDetailsText() {
      const labels = [
        { range: [0, 0], label: this.$t("evalmee.home.devices.vmScore.range.none") },
        { range: [1, 3], label: this.$t("evalmee.home.devices.vmScore.range.low") },
        { range: [3, 6], label: this.$t("evalmee.home.devices.vmScore.range.medium") },
        { range: [6, 10], label: this.$t("evalmee.home.devices.vmScore.range.high") },
      ]

      const vmScore = this.device.virtual_machine_score

      return labels.find(label => vmScore >= label.range[0] && vmScore < label.range[1])?.label
    },
  },
}
</script>
